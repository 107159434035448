// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-construction-contentful-js": () => import("../src/templates/construction-contentful.js" /* webpackChunkName: "component---src-templates-construction-contentful-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-buildings-en-js": () => import("../src/pages/buildings.en.js" /* webpackChunkName: "component---src-pages-buildings-en-js" */),
  "component---src-pages-contactos-pt-js": () => import("../src/pages/contactos.pt.js" /* webpackChunkName: "component---src-pages-contactos-pt-js" */),
  "component---src-pages-contacts-en-js": () => import("../src/pages/contacts.en.js" /* webpackChunkName: "component---src-pages-contacts-en-js" */),
  "component---src-pages-empreendimentos-pt-js": () => import("../src/pages/empreendimentos.pt.js" /* webpackChunkName: "component---src-pages-empreendimentos-pt-js" */),
  "component---src-pages-home-en-js": () => import("../src/pages/home.en.js" /* webpackChunkName: "component---src-pages-home-en-js" */),
  "component---src-pages-home-pt-js": () => import("../src/pages/home.pt.js" /* webpackChunkName: "component---src-pages-home-pt-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sobre-nos-pt-js": () => import("../src/pages/sobre-nos.pt.js" /* webpackChunkName: "component---src-pages-sobre-nos-pt-js" */)
}

